<template>
   <div class="partner-center-guest">
      <div class="banner main">
         <div class="banner-sub-title">파트너 센터</div>
         <div class="banner-title">런치팩의 파트너가 되어,<br>
          <span>더많은 프로젝트</span>를 수주하세요.
        </div>
        <div class="banner-desc">수많은 예비창업자들이 찾는 런치팩, 이곳에서 프로젝트를 찾고 성공적인 협업을 경험하세요.</div>
        <router-link to="partner_signup">
          <button class="button is-dark">파트너 가입</button>
        </router-link>
        <div class="horizontal-scroll-wrapper">
          <div ref="scroll" class="horizontal-scroll">
            <div class="card" v-for="(inquiry,index) in inquiries" :key="`inquiry-${index}`">
              <div class="card-title"><span class="service-name">{{inquiry.service_name}}</span> 서비스 제작 문의</div>
              <div class="card-info">
                <div class="card-info-col"><span class="info-label">기간</span>{{inquiry.period}}</div>
                <div class="card-info-col"><span class="info-label">예산</span>{{currency(inquiry.price)}}</div>
                <div class="card-info-col"><span class="info-label">범위</span>{{inquiry.techs}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-container">
         <div class="panel1">
            <img src="/static/img/partner_center/img1.png" alt="스킨 템플릿">
            <div>
              <div class="panel1-title">포트폴리오를 등록하고<br>
                새로운 프로젝트에 도전하세요</div>
              <div class="panel1-desc">파트너의 포트폴리오가 스킨 템플릿으로 소개됩니다.<br>
                훌륭한 플랫폼 구축 경험을 소개하고 예비창업자들의 문의를 받아보세요.</div>
              <router-link to="/template_home">
                <button class="button">
                  <span>스킨 템플릿 보러 가기</span>
                  <img class="arrow-icon" src="/static/icon/u_arrow-right.svg" alt="arrow">
                </button>
              </router-link>
            </div>
         </div>
         <div class="panel1">
            <div>
              <div class="panel1-title">기존 소스코드를 활용하여<br>
                새로운 가치를 만들어 판매하세요.</div>
              <div class="panel1-desc">파트너 개발자는 기존 소스코드를 내려받아 일부 수정한 후 또다른 템플릿을 만들어 판매할 수 있습니다. 파트너 개발자가 되어 수익을 올리고 비즈니스를 확장하세요.</div>
              <div class="panel1-desc2">* 파트너 가입 후 파트너 개발자로 신청할 수 있습니다.</div>
            </div>
            <img src="/static/img/partner_center/img2.png" alt="스킨 템플릿">
         </div>
      </div>
      <div class="banner2">
        <div class="banner2-title">지금 바로 파트너십을 맺고 시작하세요!</div>
        <div class="banner2-desc">예비창업자들이 파트너 개발사와 협력하여<br>성공적인 플랫폼 비즈니스를 창출하도록 지원합니다.</div>
        <router-link to="/partner_signup">
          <button class="button">파트너십 맺기</button>
        </router-link>
      </div>
   </div>
</template>
<script>
export default {
   name: 'PartnerCenterGuest',
   components: {
     
   },
   mixins: [],
   props: {
     
   },
   created() {
    this.inquiries = this.inquiries.concat(this.inquiries).concat(this.inquiries).concat(this.inquiries).concat(this.inquiries);
    this.cardsWidth = (460 * 4);
    this.$refs.scroll.style.left = `calc(100vw - ${this.cardsWidth + 80}px)`;
   },
   mounted() {
    this.$nextTick(() => {
      this.itv = setInterval(this.scroll, 100);
    });
   },
   destroyed() {
    clearInterval(this.itv);
   },
   data() {
     return {
       inquiries: [
        {
          service_name: '베일리',
          period: '1개월',
          price: 10000000,
          techs: '웹'
        },
        {
          service_name: '코미툰',
          period: '4개월',
          price: 50000000,
          techs: '웹'
        },
        {
          service_name: '세차왕',
          period: '2개월',
          price: 30000000,
          techs: '웹'
        },
        {
          service_name: '밈픽',
          period: '3개월',
          price: 30000000,
          techs: '모바일 앱 + 웹'
        },
       ],
       leftPx: 440,
       cardsWidth: 0,
       itv: null
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   methods: {
     scroll() {
      const scroll = this.$refs.scroll;
      this.leftPx += 5;
      scroll.style.transition = 'left 1s ease';
      if (this.$refs.scroll.getBoundingClientRect().right - window.innerWidth < 0) {
        this.leftPx = 0;
        scroll.style.transition = '';
        scroll.style.left = `calc(100vw - ${this.cardsWidth + 80}px)`;
      } else {
        scroll.style.left = `${-this.leftPx}px`;
      }
     }
   }
};
</script>
<style lang='stylus' scoped>
  @import '~assets/css/lp_main'
  .partner-center-guest
    padding 64px 0 0 0
  .banner
    background linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(71, 27, 166, 0.20) 100%), linear-gradient(103deg, #ADE6FF 2.78%, #FFF 22.07%, #DED5FF 83.46%)
    padding 80px 0 40px 0
    text-align center
  
  .banner-sub-title
    font-size 18px
    font-weight 700
    margin-bottom 24px
  .banner-title
    font-size 48px
    font-weight 700
    line-height 63px
    margin-bottom 24px
  .banner-title span
    background var(--sub-color-gradient, linear-gradient(90deg, #7B61FF 30.53%, #240E94 51.68%))
    background-clip text
    -webkit-background-clip text
    -webkit-text-fill-color transparent
  .banner-desc
    font-size 18px
    line-height 26px
    margin-bottom 40px
  .button
    width 200px
    height 52px
    font-size 16px
    font-weight 700
  .horizontal-scroll-wrapper
    overflow-x hidden
    position relative
    height 168px
  .horizontal-scroll
    display flex
    gap 20px
    margin-top 40px
    position absolute
    width max-content
    
  

  .card
    background #fff
    border-radius 16px
    padding 20px
    color #242424
    min-width 440px
    max-width 440px
    flex: 0 0 auto
    &:first-child
      margin-left 20px
    &:last-child
      margin-right 20px

    .card-title
      font-size 16px
      margin-bottom 12px
    .service-name
      font-weight 700
    .card-info
      border 1px solid $gray1
      display grid
      grid-template-columns 0.8fr 1.1fr 1fr
      border-radius 8px
      text-align left
      .card-info-col
        padding 12px 10px
        font-size 14px
        border-right 1px solid $gray1
        font-weight 500
        &:last-child
          border-right 0
      .info-label
        color $sub3
        margin-right 6px
        font-weight 400


  .panel-container
    display flex
    padding 120px 0
    gap 120px
    flex-direction column
    align-items center
    background #fff
    .panel1
      display flex
      gap 80px
      align-items center
      width 1152px
      img
        width 536px
      .panel1-title
        font-size 32px
        font-weight 700
        line-height 44px
        margin-bottom 12px
        color $main
      .panel1-desc
        font-size 18px
        line-height 26px
        color $sub2
      .panel1-desc2
        margin-top 12px
        font-size 15px
        line-height 24px
        color $sub3
      .button
        margin-top 48px
        display flex
        align-items center
        gap 6px
        width fit-content
        padding 0 24px
        height 52px
      .arrow-icon
        width 24px
        height 24px

  .banner2
    background linear-gradient(94deg, #7B61FF 8.64%, #240E94 72.94%)
    padding 80px 0
    text-align center
    color #fff
    .banner2-title
      font-size 36px
      font-weight 700
      margin-bottom 12px
    .banner2-desc
      font-size 18px
      line-height 26px
      margin-bottom 48px


  @media screen and (max-width: 600px)
    .partner-center-guest
      padding 60px 0 0 0
      .banner
        padding 80px 0 50px 0
        .banner-title
          font-size 32px
          line-height 50px
          word-break keep-all
          margin-bottom 12px
          padding 0 16px
        .banner-desc
          word-break keep-all
          padding 0 16px
          font-size 16px
          line-height 24px
          margin-bottom 40px
    
    .panel-container
      padding 60px 0
      gap 60px
      .panel1
        flex-direction column
        align-items center
        width 100%
        gap 40px
        padding 0 16px
        img
          width 100%
        .panel1-title
          font-size 24px
          line-height 36px
        .panel1-desc
          word-break keep-all
          font-size 16px
          line-height 24px
        .button
          width 100%
          margin-top 24px
        &:last-child
          flex-direction column-reverse
    .banner2
      word-break keep-all
      padding 40px 16px
      .banner2-title
        font-size 28px
        line-height 38px
        margin-bottom 12px
      .banner2-desc
        font-size 16px
        line-height 24px
        margin-bottom 24px
</style>